* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important; // delete 10px padding made by hamburger menu by mui
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: clamp(0px, 20vw, 120px);
}

.breadcrumb {
  margin-top: 20px;
}

a {
  color: black;
  text-decoration: none;
}

p {
  font-size: 1rem;
  line-height: 180%;
}

span {
  font-size: 0.8rem;
}

h2 {
  font-size: 1.2rem;
}
