.container-footer {
  margin-top: 40px;
  background-color: #262626;
  color: white;
  display: flex;
  justify-content: center;
  min-height: min-content;
}

.copyright {
  font-size: 0.5rem;
}
