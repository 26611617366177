.container-detail {
  margin: 30px 0px 0px 0px;
  padding-left: 8px;
}

.title-detail p,
.info-detail p {
  margin: 0;
}

.title-detail {
  font-weight: bold;
}

.info-detail {
  white-space: pre-line;
  padding-right: 8px;
}
