.container-news {
  border-bottom: 1px solid #e5e5e5;
  height: min-content;
  padding-left: 5px;
  padding-bottom: 10px;
}

.container-news.delighter {
  transition: all 0.3s ease-out;
  transform: translateX(-100%);
  opacity: 0;
}

.container-news.delighter.started {
  transform: none;
  opacity: 1;
}

.container-news.delighter.started.ended {
  border: solid red 10px;
}

.meta-news {
  display: flex;
  height: 1.2rem;
  align-items: center;
  margin-top: 20px;
}

.tag-news {
  display: flex;
  margin-left: 10px;
  padding: 10px;
  border-radius: 50px;
  background-color: #f15a24;
  height: 1.5rem;
  align-items: center;
  font-size: 0.8rem;
  color: white;
}

h3 {
  font-size: 0.9rem;
}
