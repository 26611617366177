.container-query {
  min-height: 100vh;
}

.text-query {
  padding-left: 20px;
}

.contact-form {
  max-width: 500px;
  display: block;
  padding: 0px 20px;

  @media (max-width: 600px) {
    padding: 0px 10px;
  }
}

.form-item {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-top: 10px;
}

input {
  height: 3em;
}

.form-button {
  width: 8em;
  padding: 1vh 0;
  margin: 30px auto 10px;
  border: solid 1px currentColor;
  color: white;
  background-color: #da4324;
  display: block;
}
