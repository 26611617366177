.top-bg {
  background-image: url(../../asset/img/bg_topimage.png);
  margin-top: -20px;
}

.wrapper-mv {
  margin-top: clamp(0px, 13vw, 100px);
}

.container-mv {
  width: 100%;
  min-height: 350px;
  background-repeat: repeat;
  background-size: cover;
  position: static;
  display: flex;
  justify-content: center;
  justify-items: flex-start;
  @media (max-width: 959px) {
    min-height: 0px;
    max-height: 300px;
    position: relative;
    justify-content: center;
  }
}

.container-mv .mainvisual {
  @media (max-width: 960px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.mainvisual {
  max-height: 350px;
  position: static;
  padding: 20px 20px 20px 0px;
  @media (max-width: 959px) {
    height: 300px;
    position: relative;
  }
}

.image-wrapper-mv {
  overflow: hidden;
  height: 260px;
  width: 100%;
  padding-bottom: 20px;
  position: relative;
  @media (min-width: 960px) {
    height: 310px;
  }
}

.image-wrapper-mv img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.message {
  height: 100%;
  width: 80%;
  justify-content: center;
  position: absolute;
  text-align: left;
  @media (min-width: 960px) {
    height: 310px;
    width: 100%;
    padding: 20px 20px 20px 0px;
    position: static;
  }
}

.message-texts {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.message-title h2 {
  font-size: clamp(22px, 3vw, 30px);
}

.message-body p {
  font-size: 1.5vw;
  font-size: clamp(13px, 2vw, 16px);
}

.message h2,
.message p {
  color: white;
  @media (min-width: 960px) {
    color: black;
  }
}
