.container-service {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  height: 300px;
  @media (max-width: 599px) {
    height: min-content;
  }
}

.content-service {
  padding-right: 10px;
}

.img-service {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-service img {
  width: 100%;
}

.img-service-toushi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-service-toushi img {
  width: 100%;
  margin-left: 40px;
}
