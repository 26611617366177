.wrapper-header {
  width: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
  height: 13vw;
  padding-left: calc((100% - 1170px) / 2);
  padding-right: calc((100% - 1170px) / 2);
  margin: 0px 10px 0px 10px;
}

.logo img {
  max-height: 50px;
  height: 7vw;
}

.menu {
  display: flex;
  justify-content: space-around;
  height: 100%;
  padding: 0px 20px;
  @media (max-width: 600px) {
    justify-content: flex-end;
    padding-right: 20px;
  }
}

.link-menu {
  font-size: clamp(12px, 2vw, 15px);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-top: solid 5px white;
}

.active {
  border-top: solid 5px #da4324;
}
