.container-contentbar {
  border-bottom: #e5e5e5 3px double;
  margin-bottom: 20px;
}

.bar-title {
  width: max-content;
  min-width: min-content;
  display: flex;
  justify-content: center;
  margin: 0 0 0 5px;
  padding: 0 5px;
  border-bottom: #f15a24 6px solid;
}

.bar-title p {
  margin: 50px 0 10px 0;
  white-space: nowrap;
}
