.wrapper-content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}

.left-column {
  padding-right: 20px;
}

.link-more {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.link-more a {
  color: #f15a24;
}
