.container-company {
  max-width: 800px;
  width: 100%;
}

.breadcrumb {
  display: flex;
}

.breadcrumb p {
  margin-right: 15px;
}
