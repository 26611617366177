.container-newsdetail {
  min-height: 100vh;
}

.meta-newsdetail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.meta-newsdetail p {
  margin-right: 20px;
}

.detail-newsdetail {
  margin-top: 40px;
  border-top: #b2b2b2 1px dashed;
  width: 800px;
  max-width: 100%;
}

.tag-newsdetail {
  display: flex;
  margin-left: 10px;
  padding: 10px;
  border-radius: 50px;
  background-color: #f15a24;
  height: 1.5rem;
  align-items: center;
  font-size: 0.8rem;
  color: white;
}
